import React from 'react'
import { Helmet } from 'react-helmet'
import { BasicLayout } from '../../layouts/basic'

const SoftwareTermsAndConditionsPage = () => (
  <BasicLayout>
    <Helmet title="Software terms and conditions — Qualdesk" />
    <div className="mx-auto p-6 max-w-6xl text-text-primary-light text-sm leading-relaxed sm:text-base">
      <h1 className="mb-6 text-2xl font-extrabold tracking-tight leading-tight sm:text-3xl">
        Software terms and conditions
      </h1>
      <p className="max-w-prose">
        Please read these Terms and Conditions carefully. All contracts that the
        Provider may enter into from time to time for the provision of the
        Hosted Services and related services shall be governed by these Terms
        and Conditions, and the Provider will ask the Customer for the
        Customer's express acceptance of these Terms and Conditions before
        providing any such services to the Customer.
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Definitions</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          Except to the extent expressly provided otherwise, in these Terms and
          Conditions:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Account" means an account enabling a person to access and use the
          Hosted Services, including both administrator accounts and user
          accounts;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Affiliate" means an entity that Controls, is Controlled by, or is
          under common Control with the relevant entity;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Agreement" means a contract between the parties incorporating these
          Terms and Conditions, and any amendments to that contract from time to
          time;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Business Day" means any weekday other than a bank or public holiday
          in England;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Business Hours" means the hours of 09:00 to 18:00 GMT/BST on a
          Business Day;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>"Charges" means the following amounts:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          such amounts as may be agreed by the parties from time to time; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          amounts calculated by multiplying the Provider's standard time-based
          charging rates (as notified by the Provider to the Customer before the
          date of the Agreement)]by the time spent by the Provider's personnel
          performing the Support Services;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Control" means the legal power to control (directly or indirectly)
          the management of an entity (and "Controlled" should be construed
          accordingly);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Customer" means the person or entity identified as such on the
          Services Order Form;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>"Customer Confidential Information" means:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          any information disclosed by or on behalf of the Customer to the
          Provider at any time before the termination of the Agreement (whether
          disclosed in writing, orally or otherwise) that at the time of
          disclosure:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(i)</span>
        <span>was marked as "confidential"; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(ii)</span>
        <span>
          should have been reasonably understood by the Provider to be
          confidential; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>the Customer Data;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Customer Data" means all data, works and materials: uploaded to or
          stored on the Platform by the Customer; transmitted by the Platform at
          the instigation of the Customer; supplied by the Customer to the
          Provider for uploading to, transmission by or storage on the Platform;
          or generated by the Platform as a result of the use of the Hosted
          Services by the Customer;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Customer Indemnity Event" has the meaning given to it in Clause 16.3;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Customer Personal Data" means any Personal Data that is processed by
          the Provider on behalf of the Customer in relation to the Agreement;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Data Protection Laws" means all applicable laws relating to the
          processing of Personal Data including, while it is in force and
          applicable to Customer Personal Data, the General Data Protection
          Regulation (Regulation (EU) 2016/679);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Documentation" means the documentation for the Hosted Services
          produced by the Provider and delivered or made available by the
          Provider to the Customer;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Effective Date" means the date upon which the parties execute a
          hard-copy Services Order Form; or, following the Customer completing
          and submitting the online Services Order Form published by the
          Provider on the Provider's website, the date upon which the Provider
          sends to the Customer an order confirmation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Force Majeure Event" means an event, or a series of related events,
          that is outside the reasonable control of the party affected
          (including failures of the internet or any public telecommunications
          network, hacker attacks, denial of service attacks, virus or other
          malicious software attacks or infections, power failures, industrial
          disputes affecting any third party, changes to the law, disasters,
          explosions, fires, floods, riots, terrorist attacks and wars);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Hosted Services" means the services as specified in the Hosted
          Services Specification, which will be made available by the Provider
          to the Customer as a service via the internet in accordance with these
          Terms and Conditions;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Hosted Services Defect" means a defect, error or bug in the Platform
          having a material adverse effect on the operation, functionality or
          performance of the Hosted Services, but excluding any defect, error or
          bug caused by or arising as a result of:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          any act or omission of the Customer or any person authorised by the
          Customer to use the Platform or Hosted Services;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          any use of the Platform or Hosted Services contrary to the
          Documentation, whether by the Customer or by any person authorised by
          the Customer;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          a failure of the Customer to perform or observe any of its obligations
          in the Agreement; and/or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          an incompatibility between the Platform or Hosted Services and any
          other system, network, application, program, hardware or software not
          specified as compatible in the Hosted Services Specification;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Hosted Services Specification" means the specification for the
          Platform and Hosted Services set out in the Services Order Form and in
          the Documentation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Intellectual Property Rights" means all intellectual property rights
          wherever in the world, whether registrable or unregistrable,
          registered or unregistered, including any application or right of
          application for such rights (and these "intellectual property rights"
          include copyright and related rights, database rights, confidential
          information, trade secrets, know-how, business names, trade names,
          trade marks, service marks, passing off rights, unfair competition
          rights, patents, petty patents, utility models, semi-conductor
          topography rights and rights in designs);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Maintenance Services" means the general maintenance of the Platform
          and Hosted Services, and the application of Updates and Upgrades;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Minimum Term" means, in respect of the Agreement, the period of 1
          month beginning on the Effective Date;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Mobile App" means the mobile application known as Qualdesk that is
          made available by the Provider through the Google Play Store or the
          Apple App Store;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Personal Data" has the meaning given to it in the General Data
          Protection Regulation (Regulation (EU) 2016/679);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Platform" means the platform managed by the Provider and used by the
          Provider to provide the Hosted Services, including the application and
          database software for the Hosted Services, the system and server
          software used to provide the Hosted Services, and the computer
          hardware on which that application, database, system and server
          software is installed;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Provider" means Qualdesk Ltd, a company incorporated in England and
          Wales (registration number 11334688) having its registered office at 7
          Henrietta Street, London, WC2E 8PS, United Kingdom;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Provider Indemnity Event" has the meaning given to it in Clause 16.1;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Services" means any services that the Provider provides to the
          Customer, or has an obligation to provide to the Customer, under these
          Terms and Conditions;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Services Order Form" means an online order form published by the
          Provider and completed and submitted by the Customer, or a hard-copy
          order form signed or otherwise agreed by or on behalf of each party,
          in each case incorporating these Terms and Conditions by reference;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Set Up Services" means the configuration, implementation and
          integration of the Hosted Services in accordance with the Services
          Order Form;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Support Services" means support in relation to the use of, and the
          identification and resolution of errors in, the Hosted Services, but
          shall not include the provision of training services;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Supported Web Browser" means the current release from time to time of
          Mozilla Firefox, Google Chrome or Apple Safari, or any other web
          browser that the Provider agrees in writing shall be supported;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Term" means the term of the Agreement, commencing in accordance with
          Clause 2.1 and ending in accordance with Clause 2.2]
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Terms and Conditions" means all the documentation containing the
          provisions of the Agreement, namely the Services Order Form, the main
          body of these Terms and Conditions and the Schedules, including any
          amendments to that documentation from time to time;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Update" means a hotfix, patch or minor version update to any Platform
          software; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          "Upgrade" means a major version upgrade of any Platform software.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>Term</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>
          The Agreement shall come into force upon the Effective Date.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2</span>
        <span>
          The Agreement shall continue in force indefinitely, subject to
          termination in accordance with Clause 20.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.3</span>
        <span>
          Unless the parties expressly agree otherwise in writing, each Services
          Order Form shall create a distinct contract under these Terms and
          Conditions.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">3.</span>
        <span>Set Up Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>
          The Provider shall provide the Set Up Services to the Customer.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.2</span>
        <span>
          The Provider shall use reasonable endeavours to ensure that the Set Up
          Services are provided in accordance with the timetable set out in the
          Services Order Form.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.3</span>
        <span>
          The Customer acknowledges that a delay in the Customer performing its
          obligations in the Agreement may result in a delay in the performance
          of the Set Up Services; and subject to Clause 18.1 the Provider will
          not be liable to the Customer in respect of any failure to meet the
          Set Up Services timetable to the extent that that failure arises out
          of a delay in the Customer performing its obligations under these
          Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.4</span>
        <span>
          Subject to any written agreement of the parties to the contrary, any
          Intellectual Property Rights that may arise out of the performance of
          the Set Up Services by the Provider shall be the exclusive property of
          the Provider.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">4.</span>
        <span>Hosted Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1</span>
        <span>
          The Platform will automatically generate an Account for the Customer
          on the Effective Date and will provide to the Customer login details
          for that Account.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.2</span>
        <span>
          The Provider hereby grants to the Customer a worldwide, non-exclusive
          licence to use the Hosted Services by means of a Supported Web Browser
          for the business purposes of the Customer in accordance with the
          Documentation during the Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.3</span>
        <span>
          The licence granted by the Provider to the Customer under Clause 4.2
          is subject to the following limitations:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Hosted Services may only be used by the officers, employees,
          agents and subcontractors of either the Customer or an Affiliate of
          the Customer;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Hosted Services may only be used by the named users identified in
          the administrator accounts, providing that the Customer may change,
          add or remove a designated named user in accordance with the procedure
          set out therein; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          the Hosted Services must not be used at any point in time by more than
          the number of concurrent users specified in the Services Order Form,
          providing that the Customer may add or remove concurrent user licences
          in accordance with the procedure set out therein.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.4</span>
        <span>
          Except to the extent expressly permitted in these Terms and Conditions
          or required by law on a non-excludable basis, the licence granted by
          the Provider to the Customer under Clause 4.2 is subject to the
          following prohibitions:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Customer must not sub-license its right to access and use the
          Hosted Services;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Customer must not permit any unauthorised person to access or use
          the Hosted Services;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>the Customer must not make any alteration to the Platform.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.5</span>
        <span>
          The Customer shall use reasonable endeavours, including reasonable
          security measures relating to Account access details, to ensure that
          no unauthorised person may gain access to the Hosted Services using an
          Account.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.6</span>
        <span>
          The parties acknowledge and agree that Schedule 2 (Availability SLA)
          shall govern the availability of the Hosted Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.7</span>
        <span>
          The Customer must comply with Schedule 1 (Acceptable Use Policy), and
          must ensure that all persons using the Hosted Services with the
          authority of the Customer or by means of an Account comply with
          Schedule 1 (Acceptable Use Policy).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.8</span>
        <span>
          The Customer must not use the Hosted Services in any way that causes,
          or may cause, damage to the Hosted Services or Platform or impairment
          of the availability or accessibility of the Hosted Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.9</span>
        <span>The Customer must not use the Hosted Services:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          in any way that is unlawful, illegal, fraudulent or harmful; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          in connection with any unlawful, illegal, fraudulent or harmful
          purpose or activity.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.10</span>
        <span>
          For the avoidance of doubt, the Customer has no right to access the
          software code (including object code, intermediate code and source
          code) of the Platform, either during or after the Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.11</span>
        <span>
          The Provider may suspend the provision of the Hosted Services if any
          amount due to be paid by the Customer to the Provider under the
          Agreement is overdue, and the Provider has given to the Customer at
          least 30 days' notice, following the amount becoming overdue, of its
          intention to suspend the Hosted Services on this basis.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">5.</span>
        <span>Support Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1</span>
        <span>
          The Provider shall provide the Support Services to the Customer during
          the Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.2</span>
        <span>
          The Provider shall provide the Support Services with reasonable skill
          and care.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.3</span>
        <span>
          The Provider shall provide the Support Services in accordance with
          Schedule 4 (Support SLA).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.4</span>
        <span>
          The Provider may suspend the provision of the Support Services if any
          amount due to be paid by the Customer to the Provider under the
          Agreement is overdue, and the Provider has given to the Customer at
          least 30 days' written notice, following the amount becoming overdue,
          of its intention to suspend the Support Services on this basis.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">6.</span>
        <span>Customer obligations</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.1</span>
        <span>
          Save to the extent that the parties have agreed otherwise in writing,
          the Customer must provide to the Provider, or procure for the
          Provider, such:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>co-operation, support and advice;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>information and documentation; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          governmental, legal and regulatory licences, consents and permits,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          as are reasonably necessary to enable the Provider to perform its
          obligations under the Agreement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.2</span>
        <span>
          The Customer must provide to the Provider, or procure for the
          Provider, such access to the Customer's computer hardware, software,
          networks and systems as may be reasonably required by the Provider to
          enable the Provider to perform its obligations under the Agreement.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">7.</span>
        <span>Customer Data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.1</span>
        <span>
          The Customer hereby grants to the Provider a non-exclusive licence to
          copy, reproduce, store, distribute, publish, export, adapt, edit and
          translate the Customer Data to the extent reasonably required for the
          performance of the Provider's obligations and the exercise of the
          Provider's rights under the Agreement. The Customer also grants to the
          Provider the right to sub-license these rights to its hosting,
          connectivity and telecommunications service providers to the extent
          reasonably required for the performance of the Provider's obligations
          and the exercise of the Provider's rights under the Agreement, subject
          always to any express restrictions elsewhere in the Agreement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.2</span>
        <span>
          The Customer warrants to the Provider that the Customer Data will not
          infringe the Intellectual Property Rights or other legal rights of any
          person, and will not breach the provisions of any law, statute or
          regulation, in any jurisdiction and under any applicable law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.3</span>
        <span>
          Unless stated on the Services Order Form, The Provider shall create a
          back-up copy of the Customer Data at least daily, shall ensure that
          each such copy is sufficient to enable the Provider to restore the
          Hosted Services to the state they were in at the time the back-up was
          taken, and shall retain and securely store each such copy for a
          minimum period of 30 days.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.4</span>
        <span>
          Within the period of 1 Business Day following receipt of a written
          request from the Customer, the Provider shall use all reasonable
          endeavours to restore to the Platform the Customer Data stored in any
          back-up copy created and stored by the Provider in accordance with
          Clause 7.3. The Customer acknowledges that this process will overwrite
          the Customer Data stored on the Platform prior to the restoration.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">8.</span>
        <span>Mobile App</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.1</span>
        <span>
          The parties acknowledge and agree that the use of the Mobile App, the
          parties' respective rights and obligations in relation to the Mobile
          App and any liabilities of either party arising out of the use of the
          Mobile App shall be subject to separate terms and conditions, and
          accordingly these Terms and Conditions shall not govern any such use,
          rights, obligations or liabilities.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">9.</span>
        <span>No assignment of Intellectual Property Rights</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.1</span>
        <span>
          Nothing in these Terms and Conditions shall operate to assign or
          transfer any Intellectual Property Rights from the Provider to the
          Customer, or from the Customer to the Provider.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">10.</span>
        <span>Charges</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.1</span>
        <span>
          The Customer shall pay the Charges to the Provider in accordance with
          these Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.2</span>
        <span>
          If the Charges are based in whole or part upon the time spent by the
          Provider performing the Services, the Provider must obtain the
          Customer's written consent before performing Services that result in
          any estimate of time-based Charges given to the Customer being
          exceeded or any budget for time-based Charges agreed by the parties
          being exceeded; and unless the Customer agrees otherwise in writing,
          the Customer shall not be liable to pay to the Provider any Charges in
          respect of Services performed in breach of this Clause 10.2.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.3</span>
        <span>
          All amounts stated in or in relation to these Terms and Conditions
          are, unless the context requires otherwise, stated exclusive of any
          applicable value added taxes, which will be added to those amounts and
          payable by the Customer to the Provider.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.4</span>
        <span>
          The Provider may elect to vary any element of the Charges by giving to
          the Customer not less than 30 days' notice of the variation.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">11.</span>
        <span>Payments</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.1</span>
        <span>
          The Provider shall issue invoices for the Charges to the Customer from
          time to time during the Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.2</span>
        <span>
          The Customer must pay the Charges to the Provider within the period of
          30 days following the issue of an invoice in accordance with this
          Clause 11.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.3</span>
        <span>
          The Customer must pay the Charges by debit card, credit card, direct
          debit, bank transfer or cheque (using such payment details as are
          notified by the Provider to the Customer from time to time).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.4</span>
        <span>
          If the Customer does not pay any amount properly due to the Provider
          under these Terms and Conditions, the Provider may:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          charge the Customer interest on the overdue amount at the rate of 8%
          per annum above the Bank of England base rate from time to time (which
          interest will accrue daily until the date of actual payment and be
          compounded at the end of each calendar month); or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          claim interest and statutory compensation from the Customer pursuant
          to the Late Payment of Commercial Debts (Interest) Act 1998.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">12.</span>
        <span>Provider's confidentiality obligations</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.1</span>
        <span>The Provider must:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          keep the Customer Confidential Information strictly confidential;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          not disclose the Customer Confidential Information to any person
          without the Customer's prior written consent;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          use the same degree of care to protect the confidentiality of the
          Customer Confidential Information as the Provider uses to protect the
          Provider's own confidential information of a similar nature, being at
          least a reasonable degree of care; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          act in good faith at all times in relation to the Customer
          Confidential Information.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.2</span>
        <span>
          Notwithstanding Clause 12.1, the Provider may disclose the Customer
          Confidential Information to the Provider's officers, employees,
          professional advisers, insurers, agents and subcontractors who have a
          need to access the Customer Confidential Information for the
          performance of their work with respect to the Agreement and who are
          bound by a written agreement or professional obligation to protect the
          confidentiality of the Customer Confidential Information.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.3</span>
        <span>
          This Clause 12 imposes no obligations upon the Provider with respect
          to Customer Confidential Information that:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          is known to the Provider before disclosure under these Terms and
          Conditions and is not subject to any other obligation of
          confidentiality;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          is or becomes publicly known through no act or default of the
          Provider; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          is obtained by the Provider from a third party in circumstances where
          the Provider has no reason to believe that there has been a breach of
          an obligation of confidentiality.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.4</span>
        <span>
          The restrictions in this Clause 12 do not apply to the extent that any
          Customer Confidential Information is required to be disclosed by any
          law or regulation, by any judicial or governmental order or request,
          or pursuant to disclosure requirements relating to the listing of the
          stock of the Provider on any recognised stock exchange.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.5</span>
        <span>
          The provisions of this Clause 12 shall continue in force for a period
          of 5 years following the termination of the Agreement, at the end of
          which period they will cease to have effect.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">13.</span>
        <span>Data protection</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.1</span>
        <span>
          Each party shall comply with the Data Protection Laws with respect to
          the processing of the Customer Personal Data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.2</span>
        <span>
          The Customer warrants to the Provider that it has the legal right to
          disclose all Personal Data that it does in fact disclose to the
          Provider under or in connection with the Agreement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.3</span>
        <span>
          The Customer shall only supply to the Provider, and the Provider shall
          only process, in each case under or in relation to the Agreement, the
          Personal Data of data subjects falling within the categories specified
          in Part 1 of Schedule 5 (Data processing information) and of the types
          specified in Part 2 of Schedule 5 (Data processing information); and
          the Provider shall only process the Customer Personal Data for the
          purposes specified in Part 3 of Schedule 5 (Data processing
          information).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.4</span>
        <span>
          The Provider shall only process the Customer Personal Data during the
          Term and for not more than 30 days following the end of the Term,
          subject to the other provisions of this Clause 13.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.5</span>
        <span>
          The Provider shall only process the Customer Personal Data on the
          documented instructions of the Customer (including with regard to
          transfers of the Customer Personal Data to any place outside the
          European Economic Area), as set out in these Terms and Conditions or
          any other document agreed by the parties.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.6</span>
        <span>
          Notwithstanding any other provision of these Terms and Conditions, the
          Provider may process the Customer Personal Data if and to the extent
          that the Provider is required to do so by applicable law. In such a
          case, the Provider shall inform the Customer of the legal requirement
          before processing, unless that law prohibits such information on
          important grounds of public interest.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.7</span>
        <span>
          The Provider shall ensure that persons authorised to process the
          Customer Personal Data have committed themselves to confidentiality or
          are under an appropriate statutory obligation of confidentiality.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.8</span>
        <span>
          The Provider and the Customer shall each implement appropriate
          technical and organisational measures to ensure an appropriate level
          of security for the Customer Personal Data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.9</span>
        <span>
          The Provider must not engage any third party to process the Customer
          Personal Data without the prior specific or general written
          authorisation of the Customer. The Provider is hereby authorised by
          the Customer, as at the Effective Date, to engage those third parties
          identified in, or falling within the processor categories specified
          in, Part 5 of Schedule 5 (Data processing information) to process the
          Customer Personal Data. In the case of a general written
          authorisation, the Provider shall inform the Customer at least 14 days
          in advance of any intended changes concerning the addition or
          replacement of any third party processor, and if the Customer objects
          to any such changes before their implementation, then the Customer may
          terminate the Agreement on 7 days' written notice to the Provider,
          providing that such notice must be given within the period of 7 days
          following the date that the Provider informed the Customer of the
          intended changes. The Provider shall ensure that each third party
          processor is subject to equivalent legal obligations as those imposed
          on the Provider by this Clause 13.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.10</span>
        <span>
          The Provider shall, insofar as possible and taking into account the
          nature of the processing, take appropriate technical and
          organisational measures to assist the Customer with the fulfilment of
          the Customer's obligation to respond to requests exercising a data
          subject's rights under the Data Protection Laws.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.11</span>
        <span>
          The Provider shall assist the Customer in ensuring compliance with the
          obligations relating to the security of processing of personal data,
          the notification of personal data breaches to the supervisory
          authority, the communication of personal data breaches to the data
          subject, data protection impact assessments and prior consultation in
          relation to high-risk processing under the Data Protection Laws.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.12</span>
        <span>
          The Provider shall make available to the Customer all information
          necessary to demonstrate the compliance of the Provider with its
          obligations under this Clause 13 and the Data Protection Laws.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.13</span>
        <span>
          The Provider shall, at the choice of the Customer, delete or return
          all of the Customer Personal Data to the Customer after the provision
          of services relating to the processing, and shall delete existing
          copies save to the extent that applicable law requires storage of the
          relevant Personal Data.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.14</span>
        <span>
          The Provider shall allow for and contribute to audits, including
          inspections, conducted by the Customer or another auditor mandated by
          the Customer in respect of the compliance of the Provider's processing
          of Customer Personal Data with the Data Protection Laws and this
          Clause 13. The Provider may charge the Customer at its standard
          time-based charging rates for any work performed by the Provider at
          the request of the Customer pursuant to this Clause 13.14.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.15</span>
        <span>
          If any changes or prospective changes to the Data Protection Laws
          result or will result in one or both parties not complying with the
          Data Protection Laws in relation to processing of Personal Data
          carried out under these Terms and Conditions, then the parties shall
          use their best endeavours promptly to agree such variations to these
          Terms and Conditions as may be necessary to remedy such
          non-compliance.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">14.</span>
        <span>Warranties</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.1</span>
        <span>The Provider warrants to the Customer that:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Provider has the legal right and authority to enter into the
          Agreement and to perform its obligations under these Terms and
          Conditions;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Provider will comply with all applicable legal and regulatory
          requirements applying to the exercise of the Provider's rights and the
          fulfilment of the Provider's obligations under these Terms and
          Conditions; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          the Provider has or has access to all necessary know-how, expertise
          and experience to perform its obligations under these Terms and
          Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.2</span>
        <span>The Provider warrants to the Customer that:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Platform and Hosted Services will conform in all material respects
          with the Hosted Services Specification;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Platform will incorporate security features reflecting the
          requirements of good industry practice.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.3</span>
        <span>
          The Provider warrants to the Customer that the Hosted Services, when
          used by the Customer in accordance with these Terms and Conditions,
          will not breach any laws, statutes or regulations applicable under
          English law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.4</span>
        <span>
          The Provider warrants to the Customer that the Hosted Services, when
          used by the Customer in accordance with these Terms and Conditions,
          will not infringe the Intellectual Property Rights of any person in
          any jurisdiction and under any applicable law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.5</span>
        <span>
          If the Provider reasonably determines, or any third party alleges,
          that the use of the Hosted Services by the Customer in accordance with
          these Terms and Conditions infringes any person's Intellectual
          Property Rights, the Provider may at its own cost and expense:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          modify the Hosted Services in such a way that they no longer infringe
          the relevant Intellectual Property Rights; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          procure for the Customer the right to use the Hosted Services in
          accordance with these Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.6</span>
        <span>
          The Customer warrants to the Provider that it has the legal right and
          authority to enter into the Agreement and to perform its obligations
          under these Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.7</span>
        <span>
          All of the parties' warranties and representations in respect of the
          subject matter of the Agreement are expressly set out in these Terms
          and Conditions. To the maximum extent permitted by applicable law, no
          other warranties or representations concerning the subject matter of
          the Agreement will be implied into the Agreement or any related
          contract.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">15.</span>
        <span>Acknowledgements and warranty limitations</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.1</span>
        <span>
          The Customer acknowledges that complex software is never wholly free
          from defects, errors and bugs; and subject to the other provisions of
          these Terms and Conditions, the Provider gives no warranty or
          representation that the Hosted Services will be wholly free from
          defects, errors and bugs.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.2</span>
        <span>
          The Customer acknowledges that complex software is never entirely free
          from security vulnerabilities; and subject to the other provisions of
          these Terms and Conditions, the Provider gives no warranty or
          representation that the Hosted Services will be entirely secure.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.3</span>
        <span>
          The Customer acknowledges that the Hosted Services are designed to be
          compatible only with that software and those systems specified as
          compatible in the Hosted Services Specification; and the Provider does
          not warrant or represent that the Hosted Services will be compatible
          with any other software or systems.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">15.4</span>
        <span>
          The Customer acknowledges that the Provider will not provide any
          legal, financial, accountancy or taxation advice under these Terms and
          Conditions or in relation to the Hosted Services; and, except to the
          extent expressly provided otherwise in these Terms and Conditions, the
          Provider does not warrant or represent that the Hosted Services or the
          use of the Hosted Services by the Customer will not give rise to any
          legal liability on the part of the Customer or any other person.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">16.</span>
        <span>Indemnities</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.1</span>
        <span>
          The Provider shall indemnify and shall keep indemnified the Customer
          against any and all liabilities, damages, losses, costs and expenses
          (including legal expenses and amounts reasonably paid in settlement of
          legal claims) suffered or incurred by the Customer and arising
          directly or indirectly as a result of any breach by the Provider of
          these Terms and Conditions (a "Provider Indemnity Event").
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.2</span>
        <span>The Customer must:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          upon becoming aware of an actual or potential Provider Indemnity
          Event, notify the Provider;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          provide to the Provider all such assistance as may be reasonably
          requested by the Provider in relation to the Provider Indemnity Event;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          allow the Provider the exclusive conduct of all disputes, proceedings,
          negotiations and settlements with third parties relating to the
          Provider Indemnity Event; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          not admit liability to any third party in connection with the Provider
          Indemnity Event or settle any disputes or proceedings involving a
          third party and relating to the Provider Indemnity Event without the
          prior written consent of the Provider,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          without prejudice to the Provider's obligations under Clause 16.1 and
          the Provider's obligation to indemnify the Customer under Clause 16.1
          shall not apply unless the Customer complies with the requirements of
          this Clause 16.2.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.3</span>
        <span>
          The Customer shall indemnify and shall keep indemnified the Provider
          against any and all liabilities, damages, losses, costs and expenses
          (including legal expenses and amounts reasonably paid in settlement of
          legal claims) suffered or incurred by the Provider and arising
          directly or indirectly as a result of any breach by the Customer of
          these Terms and Conditions (a "Customer Indemnity Event").
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.4</span>
        <span>The Provider must:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          upon becoming aware of an actual or potential Customer Indemnity
          Event, notify the Customer;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          provide to the Customer all such assistance as may be reasonably
          requested by the Customer in relation to the Customer Indemnity Event;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          allow the Customer the exclusive conduct of all disputes, proceedings,
          negotiations and settlements with third parties relating to the
          Customer Indemnity Event; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          not admit liability to any third party in connection with the Customer
          Indemnity Event or settle any disputes or proceedings involving a
          third party and relating to the Customer Indemnity Event without the
          prior written consent of the Customer,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          and the Customer's obligation to indemnify the Provider under Clause
          16.3 shall not apply unless the Provider complies with the
          requirements of this Clause 16.4.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">16.5</span>
        <span>
          The indemnity protection set out in this Clause 16 shall be subject to
          the limitations and exclusions of liability set out in the Agreement.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">17.</span>
        <span>Limitations and exclusions of liability</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.1</span>
        <span>Nothing in these Terms and Conditions will:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          limit or exclude any liability for death or personal injury resulting
          from negligence;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          limit or exclude any liability for fraud or fraudulent
          misrepresentation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          limit any liabilities in any way that is not permitted under
          applicable law; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          exclude any liabilities that may not be excluded under applicable law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.2</span>
        <span>
          The limitations and exclusions of liability set out in this Clause 17
          and elsewhere in these Terms and Conditions:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>are subject to Clause 17.1; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          govern all liabilities arising under these Terms and Conditions or
          relating to the subject matter of these Terms and Conditions,
          including liabilities arising in contract, in tort (including
          negligence) and for breach of statutory duty, except to the extent
          expressly provided otherwise in these Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.3</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          losses arising out of a Force Majeure Event.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.4</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          loss of profits or anticipated savings.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.5</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          loss of revenue or income.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.6</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          loss of use or production.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.7</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          loss of business, contracts or opportunities.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.8</span>
        <span>
          The Provider shall not be liable to the Customer in respect of any
          loss or corruption of any data, database or software; providing that
          this Clause 17.8 shall not protect the Provider unless the Provider
          has fully complied with its obligations under Clause 7.3 and Clause
          7.4.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.9</span>
        <span>
          The Provider shall not be liable to the Customer] in respect of any
          special, indirect or consequential loss or damage.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.10</span>
        <span>
          The liability of the Provider to the Customer under the Agreement in
          respect of any event or series of related events shall not exceed the
          greater of:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>one million pounds sterling; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the total amount paid and payable by the Customer to the Provider
          under the Agreement in the 12 month period preceding the commencement
          of the event or events.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">17.11</span>
        <span>
          The aggregate liability of the Provider to the Customer under the
          Agreement shall not exceed the greater of:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>one million pounds sterling and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the total amount paid and payable by the Customer to the Provider
          under the Agreement.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">18.</span>
        <span>Force Majeure Event</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">18.1</span>
        <span>
          If a Force Majeure Event gives rise to a failure or delay in either
          party performing any obligation under the Agreement (other than any
          obligation to make a payment), that obligation will be suspended for
          the duration of the Force Majeure Event.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">18.2</span>
        <span>
          A party that becomes aware of a Force Majeure Event which gives rise
          to, or which is likely to give rise to, any failure or delay in that
          party performing any obligation under the Agreement, must:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>promptly notify the other; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          inform the other of the period for which it is estimated that such
          failure or delay will continue.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">18.3</span>
        <span>
          A party whose performance of its obligations under the Agreement is
          affected by a Force Majeure Event must take reasonable steps to
          mitigate the effects of the Force Majeure Event.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">19.</span>
        <span>Termination</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">19.1</span>
        <span>
          The Provider may terminate the Agreement by giving to the Customer not
          less than 30 days' written notice of termination. The Customer may
          terminate the Agreement by giving to the Provider not less than 30
          days' written notice of termination after the end of the Minimum Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">19.2</span>
        <span>
          Either party may terminate the Agreement immediately by giving written
          notice of termination to the other party if:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the other party commits any material breach of the Agreement, and the
          breach is not remediable;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the other party commits a material breach of the Agreement, and the
          breach is remediable but the other party fails to remedy the breach
          within the period of 30 days following the giving of a written notice
          to the other party requiring the breach to be remedied; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          the other party persistently breaches the Agreement (irrespective of
          whether such breaches collectively constitute a material breach).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">19.3</span>
        <span>
          Either party may terminate the Agreement immediately by giving written
          notice of termination to the other party if:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>the other party:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(i)</span>
        <span>is dissolved;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(ii)</span>
        <span>
          ceases to conduct all (or substantially all) of its business;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(iii)</span>
        <span>is or becomes unable to pay its debts as they fall due;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(iv)</span>
        <span>is or becomes insolvent or is declared insolvent; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(v)</span>
        <span>
          convenes a meeting or makes or proposes to make any arrangement or
          composition with its creditors;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          an administrator, administrative receiver, liquidator, receiver,
          trustee, manager or similar is appointed over any of the assets of the
          other party;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          an order is made for the winding up of the other party, or the other
          party passes a resolution for its winding up (other than for the
          purpose of a solvent company reorganisation where the resulting entity
          will assume all the obligations of the other party under the
          Agreement); or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">19.4</span>
        <span>
          The Provider may terminate the Agreement immediately by giving written
          notice to the Customer if:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          any amount due to be paid by the Customer to the Provider under the
          Agreement is unpaid by the due date and remains unpaid upon the date
          that that written notice of termination is given; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Provider has given to the Customer at least 30 days' written
          notice, following the failure to pay, of its intention to terminate
          the Agreement in accordance with this Clause 20.4.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">20.</span>
        <span>Effects of termination</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">20.1</span>
        <span>
          Upon the termination of the Agreement, all of the provisions of these
          Terms and Conditions shall cease to have effect, save that the
          following provisions of these Terms and Conditions shall survive and
          continue to have effect (in accordance with their express terms or
          otherwise indefinitely): Clauses 1, 4.10, 8, 11.2, 11.4, 12, 13.1,
          13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 13.9, 13.10, 13.11, 13.12, 13.13,
          13.14, 13.15, 16, 17, 20, 23, 24, 25, 26, 27.1, 27.2, 28, 29 and 30.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">20.2</span>
        <span>
          Except to the extent that these Terms and Conditions expressly
          provides otherwise, the termination of the Agreement shall not affect
          the accrued rights of either party.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">20.3</span>
        <span>
          Within 30 days following the termination of the Agreement for any
          reason:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Customer must pay to the Provider any Charges in respect of
          Services provided to the Customer before the termination of the
          Agreement; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Provider must refund to the Customer any Charges paid by the
          Customer to the Provider in respect of Services that were to be
          provided to the Customer after the termination of the Agreement,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>without prejudice to the parties' other legal rights.</span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">21.</span>
        <span>Notices</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">21.1</span>
        <span>
          Any notice from one party to the other party under these Terms and
          Conditions must be given by one of the following methods (using the
          relevant contact details set out in Section 5 of the Services Order
          Form and Clause 22.2):
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          delivered personally or sent by courier, in which case the notice
          shall be deemed to be received upon delivery; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          sent by recorded signed-for post, in which case the notice shall be
          deemed to be received 2 Business Days following posting,
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"></span>
        <span>
          providing that, if the stated time of deemed receipt is not within
          Business Hours, then the time of deemed receipt shall be when Business
          Hours next begin after the stated time.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">21.2</span>
        <span>
          The Provider's contact details for notices under this Clause 21 are as
          follows: Qualdesk Ltd, 7 Henrietta Street, London, WC2E 8PS, United
          Kingdom.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">21.3</span>
        <span>
          The addressee and contact details set out in the Services Order Form
          and Clause 21.2 may be updated from time to time by a party giving
          written notice of the update to the other party in accordance with
          this Clause 21.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">22.</span>
        <span>Subcontracting</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">22.1</span>
        <span>
          Subject to any express restrictions elsewhere in these Terms and
          Conditions, the Provider may subcontract any of its obligations under
          the Agreement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">22.2</span>
        <span>
          The Provider shall remain responsible to the Customer for the
          performance of any subcontracted obligations.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">22.3</span>
        <span>
          Notwithstanding the provisions of this Clause 22 but subject to any
          other provision of these Terms and Conditions, the Customer
          acknowledges and agrees that the Provider may subcontract to any
          reputable third party hosting business the hosting of the Platform and
          the provision of services in relation to the support and maintenance
          of elements of the Platform.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">23.</span>
        <span>Assignment</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">23.1</span>
        <span>
          The Customer hereby agrees that the Provider may assign, transfer or
          otherwise deal with the Provider's contractual rights[ and
          obligations] under these Terms and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">23.2</span>
        <span>
          The Provider hereby agrees that the Customer may assign, transfer or
          otherwise deal with the Customer's contractual rights and obligations
          under these Terms and Conditions.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">24.</span>
        <span>No waivers</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">24.1</span>
        <span>
          No breach of any provision of the Agreement will be waived except with
          the express written consent of the party not in breach.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">24.2</span>
        <span>
          No waiver of any breach of any provision of the Agreement shall be
          construed as a further or continuing waiver of any other breach of
          that provision or any breach of any other provision of the Agreement.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">25.</span>
        <span>Severability</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">25.1</span>
        <span>
          If a provision of these Terms and Conditions is determined by any
          court or other competent authority to be unlawful and/or
          unenforceable, the other provisions will continue in effect.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">25.2</span>
        <span>
          If any unlawful and/or unenforceable provision of these Terms and
          Conditions would be lawful or enforceable if part of it were deleted,
          that part will be deemed to be deleted, and the rest of the provision
          will continue in effect.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">26.</span>
        <span>Third party rights</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">26.1</span>
        <span>
          The Agreement is for the benefit of the parties, and is not intended
          to benefit or be enforceable by any third party.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">26.2</span>
        <span>
          The exercise of the parties' rights under the Agreement is not subject
          to the consent of any third party.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">27.</span>
        <span>Variation</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">27.1</span>
        <span>
          The Agreement may not be varied except in accordance with this Clause
          27.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">27.2</span>
        <span>
          The Agreement may be varied by means of a written document signed by
          or on behalf of each party.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">27.3</span>
        <span>
          The Provider may vary the Agreement by giving to the Customer at least
          30 days' written notice of the proposed variation, providing that if
          the Provider gives to the Customer a notice under this Clause 27.3,
          the Customer shall have the right to terminate the Agreement by giving
          written notice of termination to the Provider at any time during the
          period of 14 days following receipt of the Provider's notice.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">28.</span>
        <span>Entire agreement</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">28.1</span>
        <span>
          The Services Order Form, the main body of these Terms and Conditions
          and the Schedules shall constitute the entire agreement between the
          parties in relation to the subject matter of the Agreement, and shall
          supersede all previous agreements, arrangements and understandings
          between the parties in respect of that subject matter.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">28.2</span>
        <span>
          Neither party will have any remedy in respect of any misrepresentation
          (whether written or oral) made to it upon which it relied in entering
          into the Agreement.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">28.3</span>
        <span>
          The provisions of this Clause 28 are subject to Clause 17.1.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">29.</span>
        <span>Law and jurisdiction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">29.1</span>
        <span>
          These Terms and Conditions shall be governed by and construed in
          accordance with English law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">29.2</span>
        <span>
          Any disputes relating to the Agreement shall be subject to the
          exclusive jurisdiction of the courts of England.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">30.</span>
        <span>Interpretation</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">30.1</span>
        <span>
          In these Terms and Conditions, a reference to a statute or statutory
          provision includes a reference to:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          that statute or statutory provision as modified, consolidated and/or
          re-enacted from time to time; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          any subordinate legislation made under that statute or statutory
          provision.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">30.2</span>
        <span>
          The Clause headings do not affect the interpretation of these Terms
          and Conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">30.3</span>
        <span>
          References in these Terms and Conditions to "calendar months" are to
          the 12 named periods (January, February and so on) into which a year
          is divided.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">30.4</span>
        <span>
          In these Terms and Conditions, general words shall not be given a
          restrictive interpretation by reason of being preceded or followed by
          words indicating a particular class of acts, matters or things.
        </span>
      </p>
      <h2 className="flex mb-4 mt-8 text-2xl font-medium tracking-tight leading-tight">
        Schedule 1 (Acceptable use policy)
      </h2>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Introduction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          This acceptable use policy (the "Policy") sets out the rules
          governing:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the use of the website at qualdesk.io or any subdomains, any successor
          website, and the services available on that website or any successor
          website (the "Services"); and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the transmission, storage and processing of content by you, or by any
          person on your behalf, using the Services ("Content").
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.2</span>
        <span>
          References in this Policy to "you" are to any customer for the
          Services and any individual user of the Services (and "your" should be
          construed accordingly); and references in this Policy to "us" are to
          Qualdesk Ltd (and "we" and "our" should be construed accordingly).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.3</span>
        <span>
          By using the Services, you agree to the rules set out in this Policy.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.4</span>
        <span>
          We will ask for your express agreement to the terms of this Policy
          before you upload or submit any Content or otherwise use the Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.5</span>
        <span>
          You must be at least 18 years of age to use the Services; and by using
          the Services, you warrant and represent to us that you are at least 18
          years of age.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>General usage rules</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>
          You must not use the Services in any way that causes, or may cause,
          damage to the Services or impairment of the availability or
          accessibility of the Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2</span>
        <span>You must not use the Services:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          in any way that is unlawful, illegal, fraudulent or harmful; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          in connection with any unlawful, illegal, fraudulent or harmful
          purpose or activity.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.3</span>
        <span>
          You must ensure that all Content complies with the provisions of this
          Policy.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">3.</span>
        <span>Unlawful Content</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>
          Content must not be illegal or unlawful, must not infringe any
          person's legal rights, and must not be capable of giving rise to legal
          action against any person (in each case in any jurisdiction and under
          any applicable law).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.2</span>
        <span>
          Content, and the use of Content by us in any manner licensed or
          otherwise authorised by you, must not:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>be libellous or maliciously false;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>be obscene or indecent;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          infringe any copyright, moral right, database right, trade mark right,
          design right, right in passing off, or other intellectual property
          right;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          infringe any right of confidence, right of privacy or right under data
          protection legislation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e)</span>
        <span>
          constitute negligent advice or contain any negligent statement;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f)</span>
        <span>
          constitute an incitement to commit a crime, instructions for the
          commission of a crime or the promotion of criminal activity;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(g)</span>
        <span>
          be in contempt of any court, or in breach of any court order;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(h)</span>
        <span>
          constitute a breach of racial or religious hatred or discrimination
          legislation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(i)</span>
        <span>be blasphemous;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(j)</span>
        <span>constitute a breach of official secrets legislation; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(k)</span>
        <span>
          constitute a breach of any contractual obligation owed to any person.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.3</span>
        <span>
          You must ensure that Content is not and has never been the subject of
          any threatened or actual legal proceedings or other similar complaint.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">4.</span>
        <span>Graphic material</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1</span>
        <span>
          Content must be appropriate for all persons who have access to or are
          likely to access the Content in question.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">5.</span>
        <span>Factual accuracy</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1</span>
        <span>
          Content must not be untrue, false, inaccurate or misleading.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.2</span>
        <span>
          Statements of fact contained in Content and relating to persons (legal
          or natural) must be true; and statements of opinion contained in
          Content and relating to persons (legal or natural) must be reasonable,
          be honestly held and indicate the basis of the opinion.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">6.</span>
        <span>Negligent advice</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.1</span>
        <span>
          Content must not consist of or contain any advice, instructions or
          other information that may be acted upon and could, if acted upon,
          cause death, illness or personal injury, damage to property, or any
          other loss or damage.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">7.</span>
        <span>Etiquette</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.1</span>
        <span>
          Content must be appropriate, civil and tasteful, and accord with
          generally accepted standards of etiquette and behaviour on the
          internet.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.2</span>
        <span>
          Content must not be offensive, deceptive, threatening, abusive,
          harassing, menacing, hateful, discriminatory or inflammatory.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.3</span>
        <span>
          Content must not be liable to cause annoyance, inconvenience or
          needless anxiety.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.4</span>
        <span>
          You must not use the Services to send any hostile communication or any
          communication intended to insult, including such communications
          directed at a particular person or group of people.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.5</span>
        <span>
          You must not use the Services for the purpose of deliberately
          upsetting or offending others.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.6</span>
        <span>
          You must not unnecessarily flood the Services with material relating
          to a particular subject or subject area, whether alone or in
          conjunction with others.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.7</span>
        <span>
          You must ensure that Content does not duplicate other content
          available through the Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.8</span>
        <span>You must ensure that Content is appropriately categorised.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.9</span>
        <span>
          You should use appropriate and informative titles for all Content.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.10</span>
        <span>
          You must at all times be courteous and polite to other users of the
          Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">8.</span>
        <span>Marketing and spam</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.1</span>
        <span>
          Content must not constitute or contain spam, and you must not use the
          Services to store or transmit spam - which for these purposes shall
          include all unlawful marketing communications and unsolicited
          commercial communications.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.2</span>
        <span>
          You must not use the Services to promote or operate any chain letters,
          Ponzi schemes, pyramid schemes, matrix programs, "get rich quick"
          schemes or similar letters, schemes or programs.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">9.</span>
        <span>Monitoring</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.1</span>
        <span>
          You acknowledge that we may actively monitor the Content and the use
          of the Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">10.</span>
        <span>Data mining</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.1</span>
        <span>
          You must not conduct any systematic or automated data scraping, data
          mining, data extraction or data harvesting, or other systematic or
          automated data collection activity, by means of or in relation to the
          Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">11.</span>
        <span>Hyperlinks</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.1</span>
        <span>
          You must not link to any material using or by means of the Services
          that would, if it were made available through the Services, breach the
          provisions of this Policy.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">12.</span>
        <span>Harmful software</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.1</span>
        <span>
          The Content must not contain or consist of, and you must not promote
          or distribute by means of the Services, any viruses, worms, spyware,
          adware or other harmful or malicious software, programs, routines,
          applications or technologies.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.2</span>
        <span>
          The Content must not contain or consist of, and you must not promote
          or distribute by means of the Services, any software, programs,
          routines, applications or technologies that will or may have a
          material negative effect upon the performance of a computer or
          introduce material security risks to a computer.
        </span>
      </p>
      <h2 className="flex mb-4 mt-8 text-2xl font-medium tracking-tight leading-tight">
        Schedule 2 (Availability SLA)
      </h2>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Introduction to availability SLA</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          This Schedule 2 sets out the Provider's availability commitments
          relating to the Hosted Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.2</span>
        <span>
          In this Schedule 2, "uptime" means the percentage of time during a
          given period when the Hosted Services are available at the gateway
          between public internet and the network of the hosting services
          provider for the Hosted Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>Availability</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>
          The Provider shall use reasonable endeavours to ensure that the uptime
          for the Hosted Services is at least 99.9% during each calendar month.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2</span>
        <span>
          The Provider shall be responsible for measuring uptime, and shall do
          so using any reasonable methodology.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">3.</span>
        <span>Exceptions</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>
          Downtime caused directly or indirectly by any of the following shall
          not be considered when calculating whether the Provider has met the
          uptime guarantee given in Paragraph 2.1:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>a Force Majeure Event;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          a fault or failure of the internet or any public telecommunications
          network;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          a fault or failure of the Provider's hosting infrastructure services
          provider, unless such fault or failure constitutes an actionable
          breach of the contract between the Provider and that company;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          a fault or failure of the Customer's computer systems or networks;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e)</span>
        <span>any breach by the Customer of the Agreement; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f)</span>
        <span>
          scheduled maintenance carried out in accordance with the Agreement.
        </span>
      </p>
      <p>Schedule 3 (Maintenance SLA)</p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Introduction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          This Schedule 3 sets out the service levels applicable to the
          Maintenance Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>Scheduled Maintenance Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>
          The Provider shall where practicable give to the Customer at least 10
          Business Days' prior notice of scheduled Maintenance Services that are
          likely to affect the availability of the Hosted Services or are likely
          to have a material negative impact upon the Hosted Services, without
          prejudice to the Provider's other notice obligations under this
          Schedule 3.
        </span>
      </p>
      <h2 className="flex mb-4 mt-8 text-2xl font-medium tracking-tight leading-tight">
        Schedule 4 (Support SLA)
      </h2>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Introduction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>
          This Schedule 4 sets out the service levels applicable to the Support
          Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>Helpdesk</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>
          The Provider shall make available to the Customer a helpdesk in
          accordance with the provisions of this Schedule 4.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2</span>
        <span>
          The Customer may use the helpdesk for the purposes of requesting and,
          where applicable, receiving the Support Services; and the Customer
          must not use the helpdesk for any other purpose.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.3</span>
        <span>
          The Provider shall ensure that the helpdesk is accessible by using the
          Provider's web-based ticketing system.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.4</span>
        <span>
          The Provider shall ensure that the helpdesk is operational and
          adequately staffed during Business Hours]during the Term.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.5</span>
        <span>
          The Customer shall ensure that all requests for Support Services that
          it may make from time to time shall be made through the helpdesk.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">3.</span>
        <span>Response and resolution</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>
          Issues raised through the Support Services shall be categorised as
          follows:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          critical: the Hosted Services are inoperable or a core function of the
          Hosted Services is unavailable;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          serious: a core function of the Hosted Services is significantly
          impaired;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          moderate: a core function of the Hosted Services is impaired, where
          the impairment does not constitute a serious issue; or a non-core
          function of the Hosted Services is significantly impaired; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          minor: any impairment of the Hosted Services not falling into the
          above categories; and any cosmetic issue affecting the Hosted
          Services.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.2</span>
        <span>
          The Provider shall determine, acting reasonably, into which severity
          category an issue falls.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.3</span>
        <span>
          The Provider shall use reasonable endeavours to respond to requests
          for Support Services promptly, and in any case in accordance with the
          following time periods:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>critical: 1 Business Hour;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>serious: 4 Business Hours;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>moderate: 1 Business Day; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>minor: 5 Business Days.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.4</span>
        <span>
          The Provider shall ensure that its response to a request for Support
          Services shall include the following information (to the extent such
          information is relevant to the request): an acknowledgement of receipt
          of the request, where practicable an initial diagnosis in relation to
          any reported error, and an anticipated timetable for action in
          relation to the request.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.5</span>
        <span>
          The Provider shall use all reasonable endeavours to resolve issues
          raised through the Support Services promptly, and in any case in
          accordance with the following time periods:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>critical: 2 Business Hours;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>serious: 8 Business Hours;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>moderate: 4 Business Days; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>minor: 10 Business Days.</span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">4.</span>
        <span>Provision of Support Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1</span>
        <span>
          The Support Services shall be provided remotely, save to the extent
          that the parties agree otherwise in writing.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">5.</span>
        <span>Limitations on Support Services</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1</span>
        <span>
          If the total hours spent by the personnel of the Provider performing
          the Support Services during any calendar month exceed five hours then:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>
          the Provider will cease to have an obligation to provide Support
          Services to the Customer during the remainder of that period; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          the Provider may agree to provide Support Services to the Customer
          during the remainder of that period, but the provision of those
          Support Services will be subject to additional Charges.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.2</span>
        <span>
          The Provider shall have no obligation to provide Support Services in
          respect of any issue caused by:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>the improper use of the Hosted Services by the Customer; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          any alteration to the Hosted Services made without the prior consent
          of the Provider.
        </span>
      </p>
      <h2 className="flex mb-4 mt-8 text-2xl font-medium tracking-tight leading-tight">
        Schedule 5 (Data processing information)
      </h2>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">1.</span>
        <span>Categories of data subject</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1</span>
        <span>The following categories of data subject may be processed:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>The Users as specified in Clause 4.3; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          “Research Participants”, meaning any person for whom the Customer
          processes data using the Hosted Services.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">2.</span>
        <span>Types of Personal Data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1</span>
        <span>The following types of personal data may be processed:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>Name;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          Contact information, including but not limited to email addresses,
          postal addresses and telephone numbers;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          Demographic data, including but not limited to age, ethnicity, gender,
          sexual orientation, customer segment and religious beliefs; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d)</span>
        <span>
          Data collected from research sessions, including but not limited to
          text, audio and video recordings of contributions from Research
          Participants.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">3.</span>
        <span>Purposes of processing</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1</span>
        <span>Personal data may be processed:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>For the purposes of managing user accounts;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          For the purposes of monitoring and improving the Hosted Services; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          For the purposes of qualitative research carried out by the Customer.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">4.</span>
        <span>Security measures for Personal Data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1</span>
        <span>
          Personal data that is sent to or from the Hosted Services must be
          protected using encryption technology.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="w-12">5.</span>
        <span>Sub-processors of Personal Data</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1</span>
        <span>Sub-processors may be used:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a)</span>
        <span>For the purposes of managing user accounts;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b)</span>
        <span>
          For the purposes of monitoring and improving the Hosted Services; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c)</span>
        <span>
          For the purposes of qualitative research carried out by the Customer.
        </span>
      </p>
    </div>
  </BasicLayout>
)

export default SoftwareTermsAndConditionsPage
